<template>
  <dx-modal title="Atualizar valores via CBHPM"
            id="modal-manage-cbhpm" size="lg"
            v-if="show"
            :value="show"
            @input="close">
    <div class="loading loading-lg" v-if="loading || cbhpmLoading" />
    <template v-else>
      <div class="h6 text-secondary">Regras</div>
      <div class="columns">
        <div class="column col-6 form-group">
          <label for="name" class="form-label">Convênio</label>
          <div class="cbhpm-fields text-bold text-primary">{{ plan.insurance.name }}</div>
        </div>
        <div class="column col-6 form-group">
          <label for="name" class="form-label">Plano</label>
          <div class="cbhpm-fields text-bold text-primary">{{ plan.name }}</div>
        </div>
      </div>
      <div class="columns">
        <div class="column col-6 form-group">
          <label for="name" class="form-label">Tabela de exames</label>
          <div class="cbhpm-fields">
            <div class="columns">
              <div class="column col-3">Nome:</div>
              <div class="column col-9 text-bold text-secondary">
                {{ exam.name }}
              </div>
              <div class="column col-3">Edição:</div>
              <div class="column col-9 text-bold text-small">
                {{ exam.edition.name }}
              </div>
              <div class="column col-3">Tabela:</div>
              <div class="column col-9 text-bold text-small">
                {{ exam.calculation.name }}
              </div>
              <div class="column col-3">Coeficiente:</div>
              <div class="column col-9 text-bold text-small">
                {{ exam.coefficient | number }}
              </div>
              <div class="column col-3">Custo Ope.:</div>
              <div class="column col-9 text-bold text-small">
                {{ exam.operationalCost | currency }}
              </div>
              <div class="column col-3">Filme:</div>
              <div class="column col-9 text-bold text-small">
                {{ exam.film | currency }}
              </div>
            </div>
          </div>
        </div>
        <div class="column col-6 form-group">
          <label for="name" class="form-label">Tabela de procedimentos</label>
          <div class="cbhpm-fields">
            <div class="columns">
              <div class="column col-3">Nome:</div>
              <div class="column col-9 text-bold text-secondary">
                {{ procedure.name }}
              </div>
              <div class="column col-3">Edição:</div>
              <div class="column col-9 text-bold text-small">
                {{ procedure.edition.name }}
              </div>
              <div class="column col-3">Tabela:</div>
              <div class="column col-9 text-bold text-small">
                {{ procedure.calculation.name }}
              </div>
              <div class="column col-3">Coeficiente:</div>
              <div class="column col-9 text-bold text-small">
                {{ procedure.coefficient | number }}
              </div>
              <div class="column col-3">Custo Ope.:</div>
              <div class="column col-9 text-bold text-small">
                {{ procedure.operationalCost | currency }}
              </div>
              <div class="column col-3">Filme:</div>
              <div class="column col-9 text-bold text-small">
                {{ procedure.film | currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="empty" v-if="expenseItems.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <div class="empty-title h6">Procedimentos</div>
        <div class="empty-subtitle">Nenhum procedimento encontrado.</div>
      </div>
      <template v-else>
        <div class="h6 text-secondary mt-2">Procedimentos</div>
        <table class="table table-hover">
          <thead>
          <tr>
            <th class="text-center">
              <fa-icon class="text-info" :icon="['fal', 'info-circle']" />
            </th>
            <th>Código</th>
            <th>Nome</th>
            <th class="text-right">Valor atual</th>
            <th class="text-right">Novo valor</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in expenseItems" :key="i">
            <td class="text-center">
              <span class="tooltip tooltip-right" data-tooltip="Será atualizado"
                    v-if="item.definition.newInsuranceValue">
                <fa-icon class="text-success" :icon="['fal', 'check-circle']" />
              </span>
              <span class="tooltip tooltip-right" data-tooltip="Não será atualizado" v-else>
                <fa-icon class="text-error" :icon="['fal', 'times-circle']" />
              </span>
            </td>
            <td>{{ item.code | tuss }}</td>
            <td>{{ item.name }}</td>
            <td class="text-right">{{ item.definition.insuranceValue | currency }}</td>
            <td class="text-right">
              <strong class="text-secondary" v-if="item.definition.newInsuranceValue">
                {{ item.definition.newInsuranceValue | currency }}
              </strong>
              <strong class="text-error" v-else>
                {{ item.definition.insuranceValue | currency }}
              </strong>
            </td>
          </tr>
          </tbody>
        </table>
      </template>
    </template>
    <template slot="footer">
      <button v-if="!(loading || cbhpmLoading)" class="btn btn-primary mr-1"
              :class="{loading: saving}"
              :disabled="saving"
              @click="save">
        Atualizar
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import { mergeFrom } from '@/helpers/object';

export default {
  props: {
    show: {
      type: Boolean,
    },
    origin: {
      insurance: {
        id: {
          type: String,
        },
        name: {
          type: String,
        },
        type: {
          type: String,
        },
        plan: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
        },
        settings: {
          general: {
            type: Object,
          },
          tiss: {
            type: Object,
          },
        },
      },
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      cbhpmLoading: false,
      saving: false,
      plan: this.blankPlan(),
      exam: this.blankCbhpm(),
      procedure: this.blankCbhpm(),
      expenseItems: [],
      cbhpmExpenses: [],
    };
  },
  mounted() {
    this.loadInsurancePlan();
  },
  methods: {
    loadInsurancePlan() {
      this.loading = true;
      const insuranceId = this.origin.insurance.id;
      const planId = this.origin.insurance.plan.id;
      this.$http.get(`/insurances/${insuranceId}/plans/${planId}`)
        .then(({ data }) => {
          this.plan = mergeFrom(this.blankPlan(), data);
          this.load();
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        }).finally(() => {
          this.loading = false;
        });
    },
    loadCbhpmExpenses() {
      const params = {
        edition: this.procedure.edition.code,
        codes: this.expenseItems.map(({ code }) => code).join(','),
      };
      return this.$http.get('/cbhpm-expenses', { params })
        .then(({ data }) => data.items)
        .catch(() => {});
    },
    loadCbhpmTable(id) {
      return this.$http.get(`/cbhpm-tables/${id}`)
        .then(({ data }) => data)
        .catch(() => {});
    },
    async load() {
      this.expenseItems = this.items
        .filter(item => item.selected
          && !['medicine', 'material'].includes(item.expense.type))
        .map(item => ({
          id: item.id,
          code: item.code,
          name: item.expense.name,
          type: item.expense.type,
          definition: item.definition,
        }));

      this.cbhpmLoading = true;
      if (this.plan.settings.general.table.examId) {
        this.exam = await this.loadCbhpmTable(this.plan.settings.general.table.examId);
      }
      if (this.plan.settings.general.table.procedureId) {
        this.procedure = await this.loadCbhpmTable(this.plan.settings.general.table.procedureId);
      }
      this.cbhpmExpenses = await this.loadCbhpmExpenses();

      this.expenseItems.forEach((item) => {
        const foundCbhpmItem = this.cbhpmExpenses.find(({ code }) => code === item.code);
        if (foundCbhpmItem) {
          const foundPostage = this.procedure.postageItems
            .find(({ postage }) => postage === foundCbhpmItem.postage.postage);

          const procedureFilm = this.procedure.film || 0;
          const procedureOperationalCost = this.procedure.operationalCost || 0;
          const cbhpmFilmQtd = foundCbhpmItem.film || 0;
          const filmValue = (procedureFilm * cbhpmFilmQtd) || 0;
          const cbhpmOperationalCost = foundCbhpmItem.operationalCost || 0;
          const cbhpmFraction = foundCbhpmItem.postage.fraction || 1;
          const feeValue = (foundPostage.value * cbhpmFraction) || 0;
          const operationalCost = (procedureOperationalCost * cbhpmOperationalCost);
          item.definition.filmQuantity = cbhpmFilmQtd;
          item.definition.filmValue = filmValue;
          item.definition.operationalCostValue = operationalCost;
          item.definition.feeValue = feeValue;
          item.definition.newInsuranceValue = (
            operationalCost + (filmValue * cbhpmFilmQtd) + feeValue
          );
        }
      });

      this.cbhpmLoading = false;
    },
    save() {
      this.saving = true;

      const expenseItems = this.expenseItems
        .filter(({ definition }) => definition.newInsuranceValue);

      this.$http.post('/expense-insurances/update-from-cbhpm', { expenses: expenseItems })
        .then(() => {
          this.$toast.success('Procedimentos atualizados!');
          this.$emit('saved');
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    close() {
      this.$emit('close');
    },
    blankPlan() {
      return {
        id: '',
        name: '',
        insurance: {
          id: '',
          name: '',
        },
        settings: {
          general: {
            table: {
              examId: '',
              procedureId: '',
            },
          },
        },
      };
    },
    blankCbhpm() {
      return {
        id: '',
        name: '',
        active: true,
        calculation: {
          code: '',
          name: '',
        },
        coefficient: 0,
        edition: {
          code: '',
          name: '',
        },
        factor: '',
        film: 0,
        operationalCost: 0,
        postageItems: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";

#modal-manage-cbhpm {
  .cbhpm-fields {
    background-color: $gray-color-ultra-light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    padding: $layout-spacing-sm $layout-spacing;
  }
}
</style>
