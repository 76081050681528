function removeAccentuation(text) {
  let data = text.toLowerCase();
  data = data.replace(/[ÁÀÂÃ]/gi, 'a');
  data = data.replace(/[ÉÈÊ]/gi, 'e');
  data = data.replace(/[ÍÌÎ]/gi, 'i');
  data = data.replace(/[ÓÒÔÕ]/gi, 'o');
  data = data.replace(/[ÚÙÛ]/gi, 'u');
  data += data.replace(/[Ç]/gi, 'c');
  return data;
}

// eslint-disable-next-line import/prefer-default-export
export function customName(insurance) {
  if (!insurance || !insurance.plan) return '';

  insurance.name = insurance.name.trim();
  insurance.plan.name = insurance.plan.name.trim();

  const nameToken = removeAccentuation(insurance.name).toLowerCase();
  const planNameToken = removeAccentuation(insurance.plan.name).toLowerCase();

  return nameToken === planNameToken
    ? insurance.name
    : `${insurance.name} (${insurance.plan.name})`;
}
