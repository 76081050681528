<template>
  <div>
    <dx-modal title="Profissionais com regras personalizadas" size="lg"
              id="modal-exception"
              v-if="show" :value="show" @input="close">
      <div class="loading loading-lg mt-2" v-if="loading" />
      <template v-else>
        <div class="empty mt-2" v-if="items.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Regras personalizadas</p>
          <p class="empty-subtitle">
            Nenhum profissional definido com uma regra personalizada.
          </p>
        </div>
        <template v-else>
          <label for="filter-search" class="form-label">Filtrar</label>
          <div class="input-group">
            <input type="text" id="filter-search" class="form-input"
                   autocomplete="nope" placeholder="Nome do profissional ou procedimento...">
            <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                    tabindex="-1">
              <fa-icon :icon="['fal', 'search']"/>
            </button>
          </div>
          <div class="exception-list">
            <div class="header">
              <div class="columns">
                <div class="column col-5">Profissional</div>
                <div class="column col-6">Procedimento</div>
                <div class="column col-1 text-right"></div>
              </div>
            </div>
            <div v-for="(item, i) in items" :key="i">
              <div class="body">
                <div class="columns">
                  <div class="column col-5">{{ item.name }}</div>
                  <div class="column col-6">{{ item.expense.name}}</div>
                  <div class="column col-1 text-right">
                    <button class="btn btn-sm btn-action btn-icon btn-secondary mr-1"
                            @click="editRulesModal(item, i)">
                      <fa-icon :icon="['fal', 'pencil']"></fa-icon>
                    </button>
                    <button class="btn btn-sm btn-action btn-icon btn-error"
                            :class="{loading: deleting}"
                            :disabled="deleting"
                            @click="removeException(item, i)">
                      <fa-icon :icon="['fal', 'times']"></fa-icon>
                    </button>
                  </div>
                </div>
                <div class="info">
                  <span>Convênio: {{ item.definition.insuranceValue | number }}</span> |
                  <span>Paciente: {{ item.definition.particularValue | number }}</span> |
                  <span>Repasse: {{
                    item.definition.transferValue | number
                  }}{{ item.definition.transferType === 'percent' ? '%' : '' }}</span> |
                  <span>Taxa: {{ item.definition.taxValue | number }}%</span> |
                  <span>Honorário: {{ item.definition.accredited.toUpperCase() }}</span> |
                  <span>Credenciado: {{
                      item.definition.accreditation
                      && item.definition.accreditation.enabled
                      && item.definition.accreditation.professional
                      ? item.definition.accreditation.professional.name
                      : '-'
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template slot="footer">
        <button class="btn btn-icon btn-icon-left btn-primary mr-1"
                @click="createRulesModal">
          <fa-icon :icon="['fal', 'pencil']"></fa-icon>Gerenciar regras em massa
        </button>
        <button class="btn" @click="close">Sair</button>
      </template>
    </dx-modal>

    <dx-modal title="Gerenciador de regras" size="lg"
              id="modal-exception"
              v-if="rulesModal.show"
              v-model="rulesModal.show">
      <st-tabs ref="exception-tabs" @changed="changeTabs">
        <st-tab id="rules" name="Regras">
          <div class="columns form-group">
            <div class="column col-6">
              <label class="form-checkbox">
                <input type="checkbox" v-model="rulesModal.data.update.accredited">
                Recebimento de honorário
                <i class="form-icon"></i>
                <span class="c-hand tooltip tooltip-left"
                      data-tooltip="Quando PF não deduz imposto do convênio">
                <fa-icon
                  class="text-info"
                  :icon="['fal', 'info-circle']"
                />
              </span>
              </label>
              <select class="form-select"
                      v-model="rulesModal.data.accredited"
                      :disabled="!rulesModal.data.update.accredited">
                <option value="pj">Recebido pela clínica/hospital [PJ]</option>
                <option value="pf">Recebido pelo profissional [PF]</option>
              </select>
            </div>
          </div>
          <div class="columns">
            <div class="column form-group">
              <label class="form-checkbox">
                <input type="checkbox"
                       v-model="rulesModal.data.update.insuranceValue">
                Valor convênio
                <i class="form-icon"></i>
              </label>
              <dx-input-number class="form-input"
                               v-model="rulesModal.data.insuranceValue"
                               :precision="2"
                               :disabled="!rulesModal.data.update.insuranceValue"
              />
            </div>
            <div class="column form-group">
              <label class="form-checkbox">
                <input type="checkbox"
                       v-model="rulesModal.data.update.particularValue">
                Valor paciente
                <i class="form-icon"></i>
              </label>
              <dx-input-number class="form-input"
                               v-model="rulesModal.data.particularValue"
                               :precision="2"
                               :disabled="!rulesModal.data.update.particularValue"
              />
            </div>
            <div class="column form-group">
              <label class="form-checkbox">
                <input type="checkbox" v-model="rulesModal.data.update.transferValue">
                Valor repasse
                <i class="form-icon"></i>
              </label>
              <div class="input-group">
                <dx-input-number class="form-input"
                                 v-model="rulesModal.data.transferValue"
                                 :disabled="!rulesModal.data.update.transferValue"
                                 :precision="2" maxlength="7"
                />
                <select class="form-select"
                        v-model="rulesModal.data.transferType"
                        :disabled="!rulesModal.data.update.transferValue"
                        style="max-width: 2.5rem; background-color: #f8f9fa">
                  <option value="percent">%</option>
                  <option value="value">R$</option>
                </select>
              </div>
            </div>
            <div class="column form-group">
              <label class="form-checkbox">
                <input type="checkbox"
                       v-model="rulesModal.data.update.taxValue">
                Outras taxas (%)
                <i class="form-icon"></i>
                <span class="c-hand tooltip tooltip-left"
                      data-tooltip="Taxa aplicada após a dedução de imposto">
                <fa-icon
                  class="text-info"
                  :icon="['fal', 'info-circle']"
                />
              </span>
              </label>
              <div class="input-group">
                <dx-input-number class="form-input"
                                 v-model="rulesModal.data.taxValue"
                                 :precision="2" maxlength="7"
                                 :disabled="!rulesModal.data.update.taxValue"
                />
              </div>
            </div>
            <div class="column form-group">
              <label class="form-checkbox">
                <input type="checkbox" v-model="rulesModal.data.update.reportValue">
                Valor laudo
                <i class="form-icon"></i>
              </label>
              <dx-input-number class="form-input"
                               v-model="rulesModal.data.reportValue"
                               :disabled="!rulesModal.data.update.reportValue"
                               :precision="2" maxlength="7"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column form-group">
              <label class="form-checkbox">
                <input type="checkbox"
                       v-model="rulesModal.data.update.operationalCostValue">
                Custo operacional
                <i class="form-icon"></i>
              </label>
              <dx-input-number class="form-input" :precision="2"
                               v-model="rulesModal.data.operationalCostValue"
                               :disabled="!rulesModal.data.update.operationalCostValue"
              />
            </div>
<!--            <div class="column form-group">-->
<!--              <label class="form-checkbox">-->
<!--                <input type="checkbox"-->
<!--                       v-model="rulesModal.data.update.chValue">-->
<!--                Valor de CH-->
<!--                <i class="form-icon"></i>-->
<!--              </label>-->
<!--              <dx-input-number class="form-input"-->
<!--                               v-model="rulesModal.data.chValue"-->
<!--                               :precision="2"-->
<!--                               :disabled="!rulesModal.data.update.chValue"-->
<!--              />-->
<!--            </div>-->
            <div class="column form-group">
              <label class="form-checkbox">
                <input type="checkbox" v-model="rulesModal.data.update.filmValue">
                Valor m² do filme
                <i class="form-icon"></i>
              </label>
              <dx-input-number class="form-input"
                               v-model="rulesModal.data.filmValue"
                               :disabled="!rulesModal.data.update.filmValue"
                               :precision="2" maxlength="7"
              />
            </div>
            <div class="column form-group">
              <label class="form-checkbox">
                <input type="checkbox"
                       v-model="rulesModal.data.update.filmQuantity">
                Qtde. filme
                <i class="form-icon"></i>
                <span class="c-hand tooltip tooltip-left"
                      data-tooltip="Taxa aplicada após a dedução de imposto">
                <fa-icon
                  class="text-info"
                  :icon="['fal', 'info-circle']"
                />
              </span>
              </label>
              <dx-input-number class="form-input"
                               v-model="rulesModal.data.filmQuantity"
                               :precision="2" maxlength="7"
                               :disabled="!rulesModal.data.update.filmQuantity"
              />
            </div>
            <div class="column form-group">
              <label class="form-checkbox">
                <input type="checkbox" v-model="rulesModal.data.update.feeValue">
                Valor honorário
                <i class="form-icon"></i>
              </label>
              <dx-input-number class="form-input"
                               v-model="rulesModal.data.feeValue"
                               :disabled="!rulesModal.data.update.feeValue"
                               :precision="2" maxlength="7"
              />
            </div>
          </div>
          <div class="divider" />
          <label class="form-checkbox">
            <input type="checkbox" v-model="rulesModal.data.update.checkValues">
            Atualizar parâmetros?
            <i class="form-icon"></i>
          </label>
          <div class="columns">
            <div class="column col-4 form-group">
              <label class="form-switch">
                <input type="checkbox"
                       v-model="rulesModal.data.operationalCost"
                       :disabled="!rulesModal.data.update.checkValues"
                />
                <i class="form-icon"/>Custo operacional
              </label>
              <label class="form-switch">
                <input type="checkbox"
                       v-model="rulesModal.data.film"
                       :disabled="!rulesModal.data.update.checkValues"
                />
                <i class="form-icon"/>Filme
              </label>
            </div>
            <div class="column col-4 form-group">
              <label class="form-switch">
                <input type="checkbox"
                       v-model="rulesModal.data.interest"
                       :disabled="!rulesModal.data.update.checkValues"
                />
                <i class="form-icon"/>Juros
              </label>
              <label class="form-switch">
                <input type="checkbox"
                       v-model="rulesModal.data.discount"
                       :disabled="!rulesModal.data.update.checkValues"
                />
                <i class="form-icon"/>Desconto
              </label>
            </div>
            <div class="column col-4 form-group">
              <label class="form-switch">
                <input type="checkbox"
                       v-model="rulesModal.data.rate"
                       :disabled="!rulesModal.data.update.checkValues"
                />
                <i class="form-icon"/>Taxa de cartão
              </label>
              <label class="form-switch">
                <input type="checkbox"
                       v-model="rulesModal.data.insuranceTax"
                       :disabled="!rulesModal.data.update.checkValues"
                />
                <i class="form-icon"/>Aplicar imposto do plano
              </label>
            </div>
          </div>
          <div class="divider" />
          <label class="form-switch">
            <input type="checkbox" v-model="rulesModal.data.update.checkAccreditation">
            Atualizar médico credenciado?
            <i class="form-icon"></i>
          </label>
          <div class="columns" v-if="rulesModal.data.update.checkAccreditation">
            <div class="column col-6 col-md-12 col-sm-12 form-group">
              <label class="form-switch">
                <input type="checkbox"
                       v-model="rulesModal.data.accreditation.enabled"
                />
                <i class="form-icon"/>Utilizar o credenciamento de outro profissional
              </label>
            </div>
            <div class="column col-6 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.rulesModal.data
                 .accreditation.professional.key.$error}">
              <select id="accredited" class="form-select"
                      v-model="rulesModal.data.accreditation.professional.key"
                      :disabled="!rulesModal.data.accreditation.enabled">
                <option value="">[Selecione um profissional]</option>
                <option v-for="item in accreditedProfessionals"
                        :value="item.key" :key="item.key">
                  {{ item.name }}
                  <template v-if="item.specialty">({{ item.specialty.name }})</template>
                </option>
              </select>
            </div>
          </div>
        </st-tab>
        <st-tab id="professionals" name="Profissionais" v-if="hasRules">
          <div class="input-group mt-2">
            <input type="text" class="form-input"
                   v-model="rulesModal.searchProfessional"
                   placeholder="Filtrar profissionais...">
            <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                    tabindex="-1" v-if="!rulesModal.searchProfessional.trim()">
              <fa-icon :icon="['fal', 'search']"/>
            </button>
            <button class="btn btn-gray btn-action input-group-btn btn-icon"
                    tabindex="-1" v-else @click="rulesModal.searchProfessional = ''">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
          </div>
          <table class="table table-striped table-hover">
            <thead>
            <tr>
              <th style="width: 60px">
                <label class="form-checkbox">
                  <input type="checkbox"
                         @change="selectAllProfessionals"
                         :checked="getProfessionalSelected"
                         :indeterminate.prop="getProfessionalSelected === null">
                  <i class="form-icon"></i>
                </label>
              </th>
              <th>Profissionais</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in filteredProfessionals" :key="i">
              <td>
                <label class="form-checkbox">
                  <input type="checkbox"
                         v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>
                <span class="text-secondary text-bold mr-1">{{ item.name }}</span>
                <span>({{ item.specialty.name }})</span>
              </td>
            </tr>
            </tbody>
          </table>
        </st-tab>
        <st-tab id="expenses" name="Procedimentos"
                v-if="hasRules && selectedProfessionals.length > 0">
          <div class="input-group mt-2">
            <input type="text" class="form-input"
                   v-model="rulesModal.searchExpense"
                   placeholder="Filtrar procedimentos...">
            <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                    tabindex="-1" v-if="!rulesModal.searchExpense.trim()">
              <fa-icon :icon="['fal', 'search']"/>
            </button>
            <button class="btn btn-gray btn-action input-group-btn btn-icon"
                    tabindex="-1" v-else @click="rulesModal.searchExpense = ''">
              <fa-icon :icon="['fal', 'times']"/>
            </button>
          </div>
          <table class="table table-striped table-hover">
            <thead>
            <tr>
              <th style="width: 60px">
                <label class="form-checkbox">
                  <input type="checkbox"
                         @change="selectAllExpenses"
                         :checked="getExpenseSelected"
                         :indeterminate.prop="getExpenseSelected === null">
                  <i class="form-icon"></i>
                </label>
              </th>
              <th>Procedimento</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in filteredExpenses" :key="i">
              <td>
                <label class="form-checkbox">
                  <input type="checkbox"
                         v-model="item.selected">
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>
                <span class="text-secondary text-bold mr-1">{{ item.name }}</span>
                <small>({{ item.code }})</small>
              </td>
            </tr>
            </tbody>
          </table>
        </st-tab>
        <st-tab id="finished" name="Finalizar"
                v-if="hasRules
                 && selectedProfessionals.length > 0
                 && selectedExpenses.length > 0">
          <div class="item-alert">
            <fa-icon :icon="['fal', 'exclamation-triangle']" class="mr-2" />
            <span>As informações serão atualizadas em todos os profissionais
          e procedimentos selecionados. Esta ação não poderá ser desfeita.
        </span>
          </div>
          <div class="mt-2">
            <h6 class="h6">Profissionais selecionados</h6>
            <span class="chip"
                  v-for="(item, i) in selectedProfessionals" :key="i">
              {{ item.name }}
            </span>
          </div>
          <div class="mt-2">
            <h6 class="h6">Procedimentos selecionados</h6>
            <span class="chip"
                    v-for="(item, i) in selectedExpenses" :key="i">
              {{ item.name }}
            </span>
          </div>
        </st-tab>
      </st-tabs>
      <template slot="footer">
        <button class="btn btn-icon btn-icon-left btn-primary mr-1"
                :class="{loading: rulesModal.saving}"
                :disabled="rulesModal.saving"
                @click="updateRules"
                v-if="!rulesModal.isNew">
          <fa-icon :icon="['fal', 'save']"></fa-icon>Atualizar regras
        </button>
        <button class="btn btn-icon btn-icon-left btn-primary mr-1"
                :class="{loading: rulesModal.saving}"
                :disabled="rulesModal.saving"
                @click="updateRules"
                v-if="hasRules && rulesModal.showUpdate
                 && selectedProfessionals.length > 0
                 && selectedExpenses.length > 0">
          <fa-icon :icon="['fal', 'save']"></fa-icon>Atualizar regras
        </button>
        <button class="btn" @click="rulesModal.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import fuzzy from 'fuzzy';
import { mergeFrom } from '@/helpers/object';

export default {
  props: {
    show: {
      type: Boolean,
    },
    origin: {
      insurance: {
        id: {
          type: String,
        },
        name: {
          type: String,
        },
        type: {
          type: String,
        },
        plan: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
        },
      },
    },
    expenseInsurances: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false,
      deleting: false,
      accreditedProfessionals: [],
      items: [],
      rulesModal: {
        isNew: false,
        show: false,
        showUpdate: false,
        saving: false,
        searchExpense: '',
        searchProfessional: '',
        expenses: [],
        professionals: [],
        tables: this.tables(),
        data: this.blankData(),
      },
    };
  },
  mounted() {
    this.loadExceptions();
    this.loadProfessionals();
  },
  computed: {
    hasFee() {
      return this.rulesModal.form.definition.feeValue > 0
        || this.rulesModal.form.definition.operationalCostValue > 0
        || this.rulesModal.form.definition.filmValue > 0;
    },
    getProfessionalSelected() {
      if (!this.filteredProfessionals || this.filteredProfessionals.length === 0) {
        return false;
      }

      const selected = this.filteredProfessionals
        .filter(item => item.selected);

      if (selected.length === 0) {
        return false;
      }

      if (selected.length === this.filteredProfessionals.length) {
        return true;
      }

      return null;
    },
    getExpenseSelected() {
      if (!this.filteredExpenses || this.filteredExpenses.length === 0) {
        return false;
      }

      const selected = this.filteredExpenses
        .filter(item => item.selected);

      if (selected.length === 0) {
        return false;
      }

      if (selected.length === this.filteredExpenses.length) {
        return true;
      }

      return null;
    },
    filteredProfessionals() {
      let { professionals } = this.rulesModal;
      const search = this.rulesModal.searchProfessional.trim();
      if (search) {
        const results = fuzzy.filter(search, professionals, {
          extract: (el) => {
            if (el.name) {
              return el.name;
            }
            return '';
          },
        });
        professionals = results.map(({ original }) => original);
      }

      return professionals;
    },
    filteredExpenses() {
      let { expenses } = this.rulesModal;
      const search = this.rulesModal.searchExpense.trim();
      if (search) {
        const results = fuzzy.filter(search, expenses, {
          extract: (el) => {
            if (el.name) {
              return el.name;
            }
            return '';
          },
        });
        expenses = results.map(({ original }) => original);
      }

      return expenses;
    },
    hasRules() {
      return this.rulesModal.isNew && (
        this.rulesModal.data.update.accreditation
        || this.rulesModal.data.update.accredited
        || this.rulesModal.data.update.insuranceValue
        || this.rulesModal.data.update.particularValue
        || this.rulesModal.data.update.transferValue
        || this.rulesModal.data.update.operationalCostValue
        || this.rulesModal.data.update.taxValue
        || this.rulesModal.data.update.reportValue
        || this.rulesModal.data.update.chValue
        || this.rulesModal.data.update.filmValue
        || this.rulesModal.data.update.filmQuantity
        || this.rulesModal.data.update.feeValue
        || this.rulesModal.data.update.checkValues
        || this.rulesModal.data.update.checkAccreditation
        || this.rulesModal.data.accreditation.enabled);
    },
    selectedProfessionals() {
      return this.rulesModal.professionals.filter(({ selected }) => selected);
    },
    selectedExpenses() {
      return this.rulesModal.expenses.filter(({ selected }) => selected);
    },
  },
  validations() {
    const rules = {
      rulesModal: {
        data: {
          accreditation: {
            professional: {
              key: {},
            },
          },
        },
      },
    };

    if (this.rulesModal.data.update.checkAccreditation
      && this.rulesModal.data.accreditation.enabled) {
      rules.rulesModal.data.accreditation.professional.key = { required };
    }

    return rules;
  },
  methods: {
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          items.forEach((item) => {
            item.professional.specialties.forEach((specialty) => {
              this.accreditedProfessionals.push({
                key: `${item.id}-${specialty.code}`,
                id: item.id,
                name: item.name,
                council: item.professional.councils[0],
                specialty,
              });
            });
          });
        })
        .catch(() => {});
    },
    loadExceptions() {
      this.loading = true;
      const insuranceId = this.origin.insurance.id;
      const planId = this.origin.insurance.plan.id;
      return this.$http.get(`/insurances/${insuranceId}/plans/${planId}/exceptions`)
        .then(({ data }) => {
          this.items = data.items;
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        }).finally(() => {
          this.loading = false;
        });
    },
    createRulesModal() {
      this.loadNewRulesExpenses();
      this.loadNewRulesProfessionals();
      this.rulesModal.searchProfessional = '';
      this.rulesModal.searchExpense = '';
      this.rulesModal.data = this.blankData();
      this.rulesModal.isNew = true;
      this.rulesModal.show = true;
    },
    editRulesModal(data) {
      this.loadNewRulesExpenses();
      this.loadNewRulesProfessionals();

      this.rulesModal.isNew = false;
      this.rulesModal.data = mergeFrom(this.blankData(), data.definition);

      const foundExpense = this.rulesModal.expenses
        .find(({ id }) => id === data.expense.id);

      const foundProfessional = this.rulesModal.professionals
        .find(({ id }) => id === data.id);

      if (!foundExpense || !foundProfessional) {
        this.$toast.error('Profissional ou procedimento não vinculado ao plano!');
        return;
      }

      foundExpense.selected = true;
      foundProfessional.selected = true;

      if (this.rulesModal.data.accreditation.enabled) {
        const { professional } = this.rulesModal.data.accreditation;
        const foundAccredited = this.accreditedProfessionals
          .find(item => item.id === professional.id
            && item.specialty.code === professional.specialty.code);
        if (foundAccredited) {
          foundAccredited.key = `${foundAccredited.id}-${foundAccredited.specialty.code}`;
          this.rulesModal.data.update.checkAccreditation = true;
          this.rulesModal.data.accreditation.professional = foundAccredited;
        }
      }
      this.rulesModal.show = true;
    },
    removeException(item, i) {
      return this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente remover esta regra?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              close();
              this.deleting = true;
              const data = { definition: {} };

              return this.$http
                .put(`/professionals/${item.id}/transfer-values/${item.insuranceExpenseId}`, data)
                .then(() => {
                  this.items.splice(i, 1);
                })
                .catch(() => {
                  this.$toast.error('Ocorreu um erro. Tente novamente!');
                })
                .finally(() => {
                  this.deleting = false;
                });
            },
          },
        ],
      });
    },
    close() {
      this.$emit('close');
    },
    changeTabs({ tab }) {
      this.rulesModal.showUpdate = tab.hash === 'finished';
    },
    changedAccreditation() {
      this.rulesModal.form.definition.taxType = this.rulesModal.form.definition.accredited;
    },
    calcInsuranceValueWithFee() {
      if (!this.hasFee) {
        return;
      }
      const filmValue = this.rulesModal.form.definition.filmValue || 0;
      const filmQuantity = this.rulesModal.form.definition.filmQuantity || 1;
      const ucoValue = this.rulesModal.form.definition.operationalCostValue || 0;
      const feeValue = this.rulesModal.form.definition.feeValue || 0;
      this.rulesModal.form.definition.insuranceValue = (
        filmValue * filmQuantity
      ) + ucoValue + feeValue;
    },
    selectAllProfessionals(e) {
      this.filteredProfessionals.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    loadNewRulesProfessionals() {
      this.rulesModal.professionals = this.accreditedProfessionals.map(item => ({
        id: item.id,
        name: item.name,
        council: item.council,
        specialty: item.specialty,
        selected: false,
      }));
    },
    loadNewRulesExpenses() {
      this.rulesModal.expenses = this.expenseInsurances.map(item => ({
        id: item.expense.id,
        code: item.code,
        name: item.expense.name,
        selected: false,
      }));
    },
    selectAllExpenses(e) {
      this.filteredExpenses.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    updateRules() {
      if (this.rulesModal.saving) {
        return null;
      }

      this.$v.rulesModal.data.$touch();
      if (this.$v.rulesModal.data.$error) {
        this.$refs['exception-tabs'].selectTab('rules');
        return null;
      }

      const { update } = this.rulesModal.data;
      const data = {
        professionals: this.selectedProfessionals,
        expenses: this.selectedExpenses,
      };

      if (update.checkAccreditation) {
        let accreditedProfessional = null;
        if (this.rulesModal.data.accreditation.enabled
          && this.rulesModal.data.accreditation.professional.key) {
          accreditedProfessional = this.accreditedProfessionals
            .find(({ key }) => key === this.rulesModal.data.accreditation.professional.key);
          delete accreditedProfessional.key;
        }
        data.accreditation = {
          enabled: this.rulesModal.data.accreditation.enabled || false,
          professional: accreditedProfessional,
        };
      }

      if (update.accredited) {
        data.accredited = this.rulesModal.data.accredited;
      }
      if (update.insuranceValue) {
        data.insuranceValue = this.rulesModal.data.insuranceValue;
      }
      if (update.particularValue) {
        data.particularValue = this.rulesModal.data.particularValue;
      }
      if (update.transferValue) {
        data.transferType = this.rulesModal.data.transferType;
        data.transferValue = this.rulesModal.data.transferValue;
      }
      if (update.taxValue) {
        data.taxValue = this.rulesModal.data.taxValue;
      }
      if (update.reportValue) {
        data.reportValue = this.rulesModal.data.reportValue;
      }
      if (update.operationalCostValue) {
        data.operationalCostValue = this.rulesModal.data.operationalCostValue;
      }
      if (update.chValue) {
        data.chValue = this.rulesModal.data.chValue;
      }
      if (update.filmValue) {
        data.filmValue = this.rulesModal.data.filmValue;
      }
      if (update.filmQuantity) {
        data.filmQuantity = this.rulesModal.data.filmQuantity;
      }
      if (update.feeValue) {
        data.feeValue = this.rulesModal.data.feeValue;
      }
      if (update.checkValues) {
        data.insuranceTax = this.rulesModal.data.insuranceTax;
        data.operationalCost = this.rulesModal.data.operationalCost;
        data.film = this.rulesModal.data.film;
        data.interest = this.rulesModal.data.interest;
        data.discount = this.rulesModal.data.discount;
        data.rate = this.rulesModal.data.rate;
      }

      if (Object.keys(data).length === 0) {
        this.$toast.error('Nenhuma informação habilitada para salvar!');
        return null;
      }

      return this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente atualizar os campos selecionados?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              close();
              this.rulesModal.saving = true;
              const insuranceId = this.origin.insurance.id;
              const planId = this.origin.insurance.plan.id;

              return this.$http
                .post(`/insurances/${insuranceId}/plans/${planId}/professional-bulk`, data)
                .then(() => {
                  this.rulesModal.show = false;
                  this.loadExceptions();
                })
                .catch(() => {
                  this.$toast.error('Ocorreu um erro. Tente novamente!');
                })
                .finally(() => {
                  this.rulesModal.saving = false;
                });
            },
          },
        ],
      });
    },
    tables() {
      return [
        {
          code: '00',
          name: 'Tabela própria',
        },
        {
          code: '19',
          name: 'Materiais',
        },
        {
          code: '20',
          name: 'Medicamentos',
        },
        {
          code: '22',
          name: 'Procedimentos',
        },
        {
          code: '98',
          name: 'Pacotes',
        },
      ];
    },
    blankData() {
      return {
        update: {
          accreditation: false,
          accredited: false,
          insuranceValue: false,
          particularValue: false,
          transferValue: false,
          taxValue: false,
          reportValue: false,
          operationalCostValue: false,
          chValue: false,
          filmValue: false,
          filmQuantity: false,
          feeValue: false,
          checkValues: false,
          checkAccreditation: false,
        },
        accreditation: {
          enabled: false,
          professional: {
            key: '',
            id: '',
            name: '',
            specialty: {
              code: '',
              name: '',
            },
          },
        },
        accredited: 'pj',
        chValue: '',
        chQuantity: '',
        operationalCostValue: '',
        filmValue: '',
        filmQuantity: '',
        feeValue: '',
        insuranceValue: '',
        particularValue: '',
        reportValue: '',
        taxValue: '',
        taxType: 'pj',
        transferValue: '',
        transferType: 'value',
        postageCode: '',
        tax: true,
        rate: true,
        discount: true,
        interest: true,
        operationalCost: true,
        film: true,
        insuranceTax: true,
      };
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';

#modal-exception {
  .item-alert {
    background-color: $red-color-100;
    border: $border-width solid $red-color-300;
    border-radius: $border-radius;
    font-weight: 500;
    margin: $layout-spacing 0;
    padding: $layout-spacing;
    svg {
      color: $red-color-600;
    }
  }
  .exception-list {
    margin-top: $layout-spacing;
    .header {
      background-color: #E0F4FC;
      font-weight: bold;
      padding: $layout-spacing;
    }
    .body {
      padding: $layout-spacing;
      border-bottom: $border-width solid $border-color;
      .info {
        color: $gray-color-dark;
        font-size: $font-size-xs;
      }
    }
  }
}
</style>
