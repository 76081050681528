<template>
  <div class="insurance-procedure-item" :class="form.detail ? 'selected' : ''">
    <div class="columns">
      <div class="column col-auto col-sm-12 centered">
        <label class="form-checkbox">
          <input type="checkbox"
                 v-model="form.selected"
                 @change="onSelect">
          <i class="form-icon"></i>
        </label>
      </div>
      <div class="column col-sm-12 centered">
        <span class="hover-link" @click="openDetail">
          <span v-if="type === 'plan'">{{ form.expense.name | capitalizeWord }} </span>
          <span v-else>{{ customName(form.insurance) }}</span>
          <small v-if="form.code && type === 'plan'" class="text-secondary">
            ({{ form.code }})
          </small>
          <fa-icon class="ml-2"
                   :icon="['fal', form.detail ? 'chevron-up' : 'chevron-down']"
                   v-if="form.selected && !change"
          />
        </span>
        <div v-if="form.hasProfessionalCustom">
          <small class="text-warning text-bold">
            <fa-icon :icon="['fal', 'info-circle']" />
            Este procedimento tem regras customizadas por profissional
          </small>
        </div>
      </div>
      <div class="column col-auto col-sm-12 text-right centered" style="width: 115px;">
        <dx-input-number
          class="form-input"
          v-model=form.definition.insuranceValue
          :disabled="!form.selected || hasFee"
          :precision="2"
          @change="changeValues"
        />
      </div>
      <div class="column col-auto col-sm-12 text-right centered" style="width: 115px;">
        <dx-input-number
          class="form-input"
          v-model=form.definition.particularValue
          :disabled="!form.selected"
          :precision="2"
          @change="changeValues"
        />
      </div>
      <div class="column col-auto col-sm-12 text-right centered" style="width: 150px;">
        <div class="input-group">
          <dx-input-number class="form-input"
                           v-model="form.definition.transferValue"
                           :disabled="!form.selected"
                           :precision="2" maxlength="7"
                           @change="changeValues"
          />
          <select class="form-select"
                  v-model="form.definition.transferType"
                  :disabled="!form.selected"
                  @change="changeValues"
                  style="max-width: 2.5rem; background-color: #f8f9fa">
            <option value="percent">%</option>
            <option value="value">R$</option>
          </select>
        </div>
      </div>
      <div class="column col-auto col-sm-12 text-right centered" style="width: 80px;">
        <button class="btn btn-icon btn-action mr-1" @click="cancel"
                :class="change ? 'btn-gray' : 'btn-neutral'"
                :disabled="!change" v-if="!form.detail">
          <fa-icon :icon="['fal', 'times']" />
        </button>
        <button class="btn btn-icon btn-action"
                :class="classes"
                :disabled="saving || !change"
                @click="save" v-if="!form.detail">
          <fa-icon :icon="['fal', 'save']" />
        </button>
      </div>
    </div>
    <div class="item-group" v-if="form.detail">
      <div class="columns">
        <div class="column col-2 col-md-3 col-sm-12 form-group"
             :class="{'has-error': $v.form.code.$error}">
          <label class="form-label">Código próprio</label>
          <input type="text" class="form-input text-center"
                 v-model="form.code"
                 v-mask="'00.00.00.00.00'"
                 placeholder="00.00.00.00"
                 :disabled="!form.selected"
                 @blur="$v.form.code.$touch()"
          />
        </div>
        <div class="column col-5 col-md-4 col-sm-12 form-group">
          <label class="form-label">Tabela</label>
          <select class="form-select"
                  v-model="form.tableCode"
                  :disabled="!form.selected">
            <option value="">[Selecione]</option>
            <option value="00">00 - Tabela própria</option>
            <option value="18">18 - Diárias e taxas</option>
            <option value="19">19 - Materiais</option>
            <option value="20">20 - Medicamentos</option>
            <option value="22">22 - Procedimentos</option>
            <option value="98">98 - Pacotes</option>
          </select>
        </div>
        <div class="column col-5 col-md-5 col-sm-12 form-group">
          <label class="form-label">
            Recebimento do honorário
            <span class="c-hand tooltip tooltip-left"
                  data-tooltip="Quando PF não deduz imposto do convênio">
              <fa-icon
                class="text-info"
                :icon="['fal', 'info-circle']"
              />
            </span>
          </label>
          <select id="accredited" class="form-select"
                  v-model="form.definition.accredited"
                  :disabled="!form.selected" @change="changedAccreditation">
            <option value="pf">Recebido pelo profissional [PF]</option>
            <option value="pj">Recebido pela clínica/hospital [PJ]</option>
          </select>
        </div>
        <div class="column col-2 col-md-3 col-sm-12 form-group">
          <label class="form-label">
            Outras taxas (%)
            <span class="c-hand tooltip tooltip-left"
                  data-tooltip="Taxa aplicada após a dedução de imposto">
              <fa-icon
                class="text-info"
                :icon="['fal', 'info-circle']"
              />
            </span>
          </label>
          <div class="input-group">
            <dx-input-number class="form-input"
                             v-model="form.definition.taxValue"
                             :precision="2" maxlength="7"
                             :disabled="!form.selected" />
            <select class="form-select"
                    v-model="form.definition.taxType"
                    v-if="form.definition.accredited === 'pf'"
                    style="max-width: 2.5rem; background-color: #f8f9fa">
              <option value="pf">PF</option>
              <option value="pj">PJ</option>
            </select>
          </div>
        </div>
        <div class="column col-2 col-md-3 col-sm-12 form-group">
          <label class="form-label">Valor assin. laudo</label>
          <dx-input-number class="form-input"
                           v-model="form.definition.reportValue"
                           :precision="2" maxlength="7"
                           :disabled="!form.selected"
          />
        </div>
        <div class="column col-2 col-md-3 col-sm-12 form-group">
          <label class="form-label">Custo operacional</label>
          <dx-input-number
            class="form-input"
            v-model=form.definition.operationalCostValue
            :disabled="!form.selected"
            @change="calcInsuranceValueWithFee"
            :precision="2"
          />
        </div>
<!--        <div class="column col-2 col-md-3 col-sm-12 form-group">-->
<!--          <label class="form-label">Valor de CH</label>-->
<!--          <dx-input-number-->
<!--            class="form-input"-->
<!--            v-model=form.definition.chValue-->
<!--            :disabled="!form.selected"-->
<!--            :precision="2"-->
<!--          />-->
<!--        </div>-->
        <div class="column col-2 col-md-3 col-sm-12 form-group">
          <label class="form-label">Valor m² do filme</label>
          <dx-input-number
            class="form-input"
            v-model=form.definition.filmValue
            :disabled="!form.selected"
            @change="calcInsuranceValueWithFee"
            :precision="2"
          />
        </div>
        <div class="column col-2 col-md-3 col-sm-12 form-group">
          <label class="form-label">Qtde. filme</label>
          <dx-input-number
            class="form-input"
            v-model=form.definition.filmQuantity
            :disabled="!form.selected"
            @change="calcInsuranceValueWithFee"
            :precision="2"
          />
        </div>
        <div class="column col-2 col-md-3 col-sm-12 form-group">
          <label class="form-label">Valor honorário</label>
          <dx-input-number
            class="form-input"
            v-model=form.definition.feeValue
            :disabled="!form.selected"
            @change="calcInsuranceValueWithFee"
            :precision="2"
          />
        </div>
        <div class="column col-4 col-md-12 col-sm-12">
          <label class="form-switch">
            <input type="checkbox"
                   :disabled="!form.selected"
                   v-model="form.requireAuthorization"/>
            <i class="form-icon"/>Requer autorização do convênio
          </label>
        </div>
        <div class="column col-4 col-md-12 col-sm-12">
          <label class="form-switch">
            <input type="checkbox"
                   :disabled="!form.selected"
                   v-model="form.requireClinicalIndication"/>
            <i class="form-icon"/>Obrigatório indicação clínica
          </label>
        </div>
        <div class="column col-4 col-md-12 col-sm-12">
          <label class="form-switch">
            <input type="checkbox"
                   v-model="form.requireWayTechnique"
                   :disabled="!form.selected"/>
            <i class="form-icon"/>Obrigatório campos via e técnica
          </label>
        </div>
      </div>
      <div class="divider" />
      <span class="text-bold">Repassar aos profissionais os custos e acréscimos de:</span>
      <div class="columns">
        <div class="column col-4 col-sm-12 form-group">
          <label class="form-switch">
            <input type="checkbox"
                   v-model="form.definition.operationalCost"
                   :disabled="!form.selected"/>
            <i class="form-icon"/>Custo operacional
          </label>
          <label class="form-switch">
            <input type="checkbox"
                   v-model="form.definition.film"
                   :disabled="!form.selected"/>
            <i class="form-icon"/>Filme
          </label>
        </div>
        <div class="column col-4 col-sm-12 form-group">
          <label class="form-switch">
            <input type="checkbox"
                   v-model="form.definition.interest"
                   :disabled="!form.selected"/>
            <i class="form-icon"/>Juros
          </label>
          <label class="form-switch">
            <input type="checkbox"
                   v-model="form.definition.discount"
                   :disabled="!form.selected"/>
            <i class="form-icon"/>Desconto
          </label>
        </div>
        <div class="column col-4 col-sm-12 form-group">
          <label class="form-switch">
            <input type="checkbox"
                   v-model="form.definition.rate"
                   :disabled="!form.selected"/>
            <i class="form-icon"/>Taxa do cartão
          </label>
          <label class="form-switch">
            <input type="checkbox"
                   v-model="form.definition.insuranceTax"
                   :disabled="!form.selected || form.definition.accredited === 'pf'"/>
            <i class="form-icon"/>Aplicar imposto do plano
          </label>
        </div>
      </div>
      <div class="divider" />
      <div class="columns">
        <div class="column col-6 col-md-12 col-sm-12 form-group">
          <label class="form-switch">
            <input type="checkbox"
                   v-model="form.definition.accreditation.enabled"
                   :disabled="!form.selected"
            />
            <i class="form-icon"/>Utilizar o credenciamento de outro profissional
          </label>
        </div>
        <div class="column col-6 col-md-12 col-sm-12 form-group"
             :class="{'has-error': $v.form.definition.accreditation.professional.key.$error}">
          <select id="accredited" class="form-select"
                  v-model="form.definition.accreditation.professional.key"
                  :disabled="!form.definition.accreditation.enabled || !form.selected">
            <option value="">[Selecione um profissional]</option>
            <option v-for="item in professionals" :value="item.key" :key="item.key">
              {{ item.name }}
              <template v-if="item.specialty">({{ item.specialty.name }})</template>
            </option>
          </select>
        </div>
      </div>
      <div class="text-right mt-1">
        <button class="btn btn-info btn-icon btn-icon-left float-left"
                :class="{loading: modalProfessionalException.loading}"
                :disabled="modalProfessionalException.loading"
                v-if="form.hasProfessionalCustom"
                @click="openProfessionalException">
          <fa-icon :icon="['fal', 'user']" />Verificar profissionais
        </button>
        <button class="btn btn-gray btn-icon btn-icon-left mr-1"
                @click="form.detail = false">
          <fa-icon :icon="['fal', 'times']" />Fechar
        </button>
        <button class="btn btn-primary btn-icon btn-icon-left"
                :class="{loading: saving}"
                :disabled="saving"
                @click="save">
          <fa-icon :icon="['fal', 'save']" />Salvar
        </button>
      </div>
    </div>

    <dx-modal :title="professionalTitle"
              id="modal-professional-exception"
              size="lg"
              v-if="modalProfessionalException.show"
              v-model="modalProfessionalException.show">
      <table class="table table-striped table-hover">
        <thead>
        <tr>
          <th>Nome</th>
          <th class="text-center">Customizado</th>
          <th class="text-center">Honorário</th>
          <th class="text-right">Taxas</th>
          <th class="text-right">Val. convênio</th>
          <th class="text-right">Val. paciente</th>
          <th class="text-right">Val. repasse</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in modalProfessionalException.items"
            :class="item.custom ? 'text-warning text-bold' : ''"
            :key="i">
          <td>
            <router-link
              :to="openProfessionalLink(item.id)" target="_blank">
              {{ item.name }}
            </router-link>
          </td>
          <td class="text-center">{{ item.custom ? 'Sim' : 'Não' }}</td>
          <td class="text-center">{{ item.accredited.toString().toUpperCase() }}</td>
          <td class="text-right">{{ item.taxValue }}%</td>
          <td class="text-right">{{ item.insuranceValue | currency }}</td>
          <td class="text-right">{{ item.particularValue | currency }}</td>
          <td class="text-right">
            <span v-if="item.transferType === 'percent'">
              {{ item.transferValue }}%
            </span>
            <span v-else>{{ item.transferValue | currency }}</span>
          </td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn" @click="modalProfessionalException.show = false">Sair</button>
      </template>
    </dx-modal>

    <dx-modal title="Regras personalizadas"
              id="modal-rules"
              v-if="modalRules.show"
              v-model="modalRules.show">
      <p class="text-info">
        Identificamos que este procedimento possui uma ou mais
        regras personalizadas por profissional.
      </p>
      <p class="text-error text-bold">
        ATENÇÃO: Deseja atualizar os valores das regras personalizadas dos profissionais?
      </p>
      <template slot="footer">
        <button class="btn btn-secondary btn-icon btn-icon-left mr-1"
                @click="create(true)">
          <fa-icon :icon="['fal', 'check']"/>Sim
        </button>
        <button class="btn btn-error btn-icon btn-icon-left mr-1"
                @click="create(false)">
          <fa-icon :icon="['fal', 'times']"/>Não, atualize apenas a principal
        </button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { customName } from 'src/helpers/insurance';
import formMixin from 'src/mixins/form';
import { mergeFrom } from 'src/helpers/object';
import { required } from 'vuelidate/lib/validators';

export default {
  props: {
    type: {
      type: String,
    },
    data: {
      type: Object,
    },
    professionals: {
      type: Array,
    },
  },
  mixins: [formMixin],
  data() {
    return {
      change: false,
      saving: false,
      customName,
      form: this.blankForm(),
      preForm: this.blankForm(),
      modalProfessionalException: {
        show: false,
        loading: false,
        items: [],
      },
      modalRules: {
        show: false,
      },
    };
  },
  computed: {
    professionalTitle() {
      let name = this.form.expense.name.toString();
      if (name.length > 70) {
        name = `${name.substr(0, 70)}...`;
      }
      return `Profissionais para: [${name}]`;
    },
    hasFee() {
      return this.form.definition.feeValue > 0
        || this.form.definition.operationalCostValue > 0
        || this.form.definition.filmValue > 0;
    },
    classes() {
      const items = [this.change ? 'btn-primary' : 'btn-neutral'];

      if (this.saving) {
        items.push('loading');
      }

      return items;
    },
  },
  mounted() {
    this.form = mergeFrom(this.blankForm(), this.data);
    this.preForm = mergeFrom(this.blankForm(), this.data);
  },
  validations() {
    const rules = {
      form: {
        code: { required },
        definition: {
          accreditation: {
            professional: {
              key: {},
            },
          },
        },
      },
    };
    if (this.form.definition.accreditation.enabled) {
      rules.form.definition.accreditation.professional.key = { required };
    }

    return rules;
  },
  methods: {
    setInitialValues() {
      this.form.selected = this.preForm.selected;
      this.form.definition.insuranceValue = this.preForm.definition.insuranceValue;
      this.form.definition.particularValue = this.preForm.definition.particularValue;
      this.form.definition.transferValue = this.preForm.definition.transferValue;
      this.form.definition.transferType = this.preForm.definition.transferType;
    },
    updateInitialValues(data) {
      this.preForm.selected = data.selected;
      this.preForm.definition.insuranceValue = data.definition.insuranceValue;
      this.preForm.definition.particularValue = data.definition.particularValue;
      this.preForm.definition.transferValue = data.definition.transferValue;
      this.preForm.definition.transferType = data.definition.transferType;
    },
    onSelect() {
      this.changeValues();
      this.form.detail = false;
    },
    changeValues() {
      const changedSelect = this.preForm.selected !== this.form.selected;
      const changedInsuranceValue = this.preForm.definition.insuranceValue
        !== this.form.definition.insuranceValue;
      const changedParticularValue = this.preForm.definition.particularValue
        !== this.form.definition.particularValue;
      const changedTransferValue = this.preForm.definition.transferValue
        !== this.form.definition.transferValue;
      const changedTransferType = this.preForm.definition.transferType
        !== this.form.definition.transferType;
      this.change = changedInsuranceValue
        || changedSelect
        || changedParticularValue
        || changedTransferValue
        || changedTransferType;
    },
    changedAccreditation() {
      this.form.definition.taxType = this.form.definition.accredited;
    },
    cancel() {
      this.change = false;
      this.form.detail = false;
      this.setInitialValues();
    },
    openDetail() {
      if (!this.form.selected || this.change) {
        return;
      }
      this.form.detail = !this.form.detail;
    },
    openProfessionalException() {
      this.modalProfessionalException.loading = true;
      this.$http.get(`/expense-insurances/${this.form.id}/professionals`)
        .then(({ data }) => {
          this.modalProfessionalException.items = data.items;
          this.modalProfessionalException.show = true;
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.modalProfessionalException.loading = false;
        });
    },
    openProfessionalLink(id) {
      return `/clinical/professionals/${id}/edit`;
    },
    calcInsuranceValueWithFee() {
      if (!this.hasFee) {
        return;
      }
      const filmValue = this.form.definition.filmValue || 0;
      const filmQuantity = this.form.definition.filmQuantity || 1;
      const ucoValue = this.form.definition.operationalCostValue || 0;
      const feeValue = this.form.definition.feeValue || 0;
      this.form.definition.insuranceValue = (filmValue * filmQuantity) + ucoValue + feeValue;
    },
    save() {
      if (this.form.selected) {
        this.create();
      } else {
        this.remove();
      }
    },
    remove() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      this.$http
        .delete(`/expense-insurances/${this.form.id}`)
        .then(() => {
          this.$toast.show('Removido com sucesso!');
          this.form.id = '';
          this.preForm.selected = false;
          this.change = false;
        })
        .catch(() => {
          this.$toast.show('Ocorreu um erro. Tente novamente!', { type: 'error' });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    create(updateExceptions) {
      this.$v.form.$touch();
      if (this.saving || this.$v.form.$error) {
        if (this.$v.form.$error) {
          this.form.detail = true;
        }
        return null;
      }

      const data = this.clone(this.form);
      data.updateExceptions = !!updateExceptions;

      if (this.form.definition.accreditation.enabled) {
        const foundProfessional = this.professionals
          .find(({ key }) => key === this.form.definition.accreditation.professional.key);
        data.definition.accreditation.professional = {
          _id: foundProfessional.id,
          name: foundProfessional.name,
          council: foundProfessional.council,
          specialty: foundProfessional.specialty,
        };
      } else {
        data.definition.accreditation.professional = null;
      }

      if (this.form.hasProfessionalCustom && !this.modalRules.show) {
        this.modalRules.show = true;
        return null;
      }

      return this.postData(data);
    },
    postData(data) {
      this.saving = true;
      return this.$http
        .post('/expense-insurances', data)
        .then(({ data: result }) => {
          this.form.id = result.id;
          this.updateInitialValues(data);
          this.$toast.show('Salvo com sucesso!');
        })
        .catch(() => {
          this.$toast.show('Ocorreu um erro. Tente novamente!', { type: 'error' });
        })
        .finally(() => {
          this.saving = false;
          this.change = false;
          this.modalRules.show = false;
        });
    },
    blankInsurance() {
      return {
        id: '',
        name: '',
        type: '',
        plan: {
          id: '',
          name: '',
        },
      };
    },
    blankExpense() {
      return {
        id: '',
        name: '',
        type: '',
        tuss: '',
        specialty: '',
        modality: '',
      };
    },
    blankDefinition() {
      return {
        accreditation: {
          enabled: false,
          professional: {
            key: '',
            id: '',
            name: '',
            council: {
              name: '',
              state: '',
              record: '',
            },
            specialty: {
              code: '',
              name: '',
            },
          },
        },
        accredited: 'pj',
        chValue: '',
        chQuantity: '',
        operationalCostValue: '',
        filmValue: '',
        filmQuantity: '',
        feeValue: '',
        insuranceValue: '',
        particularValue: '',
        reportValue: '',
        taxValue: '',
        taxType: 'pj',
        transferValue: '',
        transferType: 'value',
        postageCode: '',
        tax: true,
        rate: true,
        discount: true,
        interest: true,
        operationalCost: true,
        film: true,
        insuranceTax: true,
      };
    },
    blankForm() {
      return {
        id: '',
        code: '',
        tableCode: '',
        insurance: this.blankInsurance(),
        expense: this.blankExpense(),
        return: '30',
        breakInsurance: false,
        professionals: 1,
        requireAuthorization: false,
        requireClinicalIndication: false,
        requireWayTechnique: false,
        hasProfessionalCustom: false,
        definition: this.blankDefinition(),
        selected: false,
        detail: false,
        search: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";

.insurance-procedure-item {
  border-bottom: $border-width solid $border-color;
  padding: $layout-spacing-sm $layout-spacing;
  &.selected {
    background-color: $gray-color-ultra-light;
  }
  .hover-link {
    font-size: $font-size-sm;
    font-weight: bold;
  }
  .item-group {
    border-top: $border-width solid $border-color;
    margin-top: $layout-spacing;
    padding: $layout-spacing;
  }
}
</style>
