<template>
  <div class="insurance-procedures">
    <div class="loading loading-lg" v-if="loading" />
    <template v-else>
      <div class="empty mt-2" v-if="items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <div class="empty-title h6">Procedimentos</div>
        <div class="empty-subtitle">
          <slot>Nenhum procedimento cadastrado.</slot>
        </div>
      </div>
      <div v-else>
        <div class="columns">
          <div class="column">
            <label class="form-label">Pesquisar</label>
            <div class="input-group">
              <input type="text" class="form-input" v-model="filters.search"
                     placeholder="Filtrar procedimentos...">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                      tabindex="-1" v-if="!filters.search.trim()">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <button class="btn btn-gray btn-action input-group-btn btn-icon"
                      tabindex="-1" v-else @click="filters.search = ''">
                <fa-icon :icon="['fal', 'times']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto" v-if="origin.type === 'plan'"
               style="display: flex; align-self: flex-end">
            <div class="text-right mt-2">
              <button class="btn btn-icon btn-icon-left btn-secondary mr-1"
                      v-if="generalTable.examId || generalTable.procedureId"
                      @click="openCbhpmModal">
                <fa-icon :icon="['fal', 'sync']" />Atualizar via CBHPM
              </button>
              <button class="btn btn-icon btn-icon-left btn-primary mr-1"
                      @click="exceptionModalShow = true">
                <fa-icon :icon="['fal', 'info-circle']" />Exceções
              </button>
              <button class="btn btn-icon btn-icon-left btn-primary mr-1"
                      @click="openMassChange">
                <fa-icon :icon="['fal', 'pencil']" />Alteração em massa
              </button>
              <button class="btn btn-icon btn-icon-left btn-primary mr-1"
                      @click="openClone">
                <fa-icon :icon="['fal', 'copy']" />Copiar valores
              </button>
              <dx-dropdown
                class="btn btn-icon btn-action btn-setting"
                :class="{loading: importExportModal.saving}"
                :disabled="importExportModal.saving"
                :items="[
                  { value: 'export', text: 'Exportar valores' },
                  { value: 'import', text: 'Importar valores' },
                 ]"
                @select="setAction"
                label="text"
                direction="right">
                <fa-icon :icon="['fal', 'cog']" v-if="!importExportModal.saving" />
              </dx-dropdown>
            </div>
          </div>
        </div>
        <div class="procedure-title">
          <div class="columns">
            <div class="column col-sm-12 centered">
              {{ origin.type === 'plan' ? 'Procedimentos' : 'Convênios' }}
            </div>
            <div class="column col-auto col-sm-12 centered text-right" style="width: 115px;">
              Valor convênio
            </div>
            <div class="column col-auto col-sm-12 centered text-right" style="width: 115px;">
              Valor paciente
            </div>
            <div class="column col-auto col-sm-12 centered text-right" style="width: 150px;">
              Valor repasse
            </div>
            <div class="column col-auto col-sm-12 text-right" style="width: 80px;"></div>
          </div>
        </div>
        <plan-procedure-insurance
          class="expense-insurances"
          v-for="item in filteredItems"
          :type="origin.type"
          :key="origin.type === 'plan' ? item.expense.id : item.insurance.plan.id"
          :data="item"
          :professionals="professionals"
        />
      </div>
    </template>

    <dx-modal title="Alteração em massa"
              id="modal-mass-change" size="lg"
              v-if="massChangeModal.show"
              v-model="massChangeModal.show">
      <div class="item-alert">
        <fa-icon :icon="['fal', 'exclamation-triangle']" class="mr-2" />
        <span>As alterações realizadas nesta tela serão aplicadas em todos os
          procedimentos selecionados e não poderão ser desfeitos.
        </span>
      </div>
      <h6 class="h6">Apenas os campos ativos serão atualizados</h6>
      <div class="divider" />
      <div class="columns">
        <div class="column col-6 col-md-6 col-sm-12 form-group">
          <label class="form-checkbox">
            <input type="checkbox" v-model="massChangeModal.data.update.tableCode">
            Tabela
            <i class="form-icon"></i>
          </label>
          <select class="form-select"
                  v-model="massChangeModal.data.tableCode"
                  :disabled="!massChangeModal.data.update.tableCode"
                  @blur="$v.massChangeModal.data.tableCode.$touch()">
            <option v-for="item in massChangeModal.tables"
                    :value="item.code" :key="item.code">
              {{ item.code }} - {{ item.name }}
            </option>
          </select>
        </div>
        <div class="column col-6 col-md-6 col-sm-12 form-group">
          <label class="form-checkbox">
            <input type="checkbox" v-model="massChangeModal.data.update.accredited">
            Recebimento de honorário
            <i class="form-icon"></i>
            <span class="c-hand tooltip tooltip-left"
                  data-tooltip="Quando PF não deduz imposto do convênio">
                <fa-icon
                  class="text-info"
                  :icon="['fal', 'info-circle']"
                />
              </span>
          </label>
          <select class="form-select"
                  v-model="massChangeModal.data.accredited"
                  :disabled="!massChangeModal.data.update.accredited"
                  @blur="$v.massChangeModal.data.accredited.$touch()">
            <option value="pj">Recebido pela clínica/hospital [PJ]</option>
            <option value="pf">Recebido pelo profissional [PF]</option>
          </select>
        </div>
      </div>
      <div class="divider" />
      <div class="columns">
        <div class="column form-group">
          <label class="form-checkbox">
            <input type="checkbox"
                   v-model="massChangeModal.data.update.insuranceValue">
            Valor convênio
            <i class="form-icon"></i>
          </label>
          <dx-input-number class="form-input"
                           v-model="massChangeModal.data.insuranceValue"
                           :precision="2"
                           :disabled="!massChangeModal.data.update.insuranceValue"
          />
        </div>
        <div class="column form-group">
          <label class="form-checkbox">
            <input type="checkbox"
                   v-model="massChangeModal.data.update.particularValue">
            Valor paciente
            <i class="form-icon"></i>
          </label>
          <dx-input-number class="form-input"
                           v-model="massChangeModal.data.particularValue"
                           :precision="2"
                           :disabled="!massChangeModal.data.update.particularValue"
          />
        </div>
        <div class="column form-group">
          <label class="form-checkbox">
            <input type="checkbox" v-model="massChangeModal.data.update.transferValue">
            Valor repasse
            <i class="form-icon"></i>
          </label>
          <div class="input-group">
            <dx-input-number class="form-input"
                             v-model="massChangeModal.data.transferValue"
                             :disabled="!massChangeModal.data.update.transferValue"
                             :precision="2" maxlength="7"
            />
            <select class="form-select"
                    v-model="massChangeModal.data.transferType"
                    :disabled="!massChangeModal.data.update.transferValue"
                    style="max-width: 2.5rem; background-color: #f8f9fa">
              <option value="percent">%</option>
              <option value="value">R$</option>
            </select>
          </div>
        </div>
        <div class="column form-group">
          <label class="form-checkbox">
            <input type="checkbox"
                   v-model="massChangeModal.data.update.taxValue">
            Outras taxas (%)
            <i class="form-icon"></i>
            <span class="c-hand tooltip tooltip-left"
                  data-tooltip="Taxa aplicada após a dedução de imposto">
                <fa-icon
                  class="text-info"
                  :icon="['fal', 'info-circle']"
                />
              </span>
          </label>
          <div class="input-group">
            <dx-input-number class="form-input"
                             v-model="massChangeModal.data.taxValue"
                             :precision="2" maxlength="7"
                             :disabled="!massChangeModal.data.update.taxValue"
            />
          </div>
        </div>
        <div class="column form-group">
          <label class="form-checkbox">
            <input type="checkbox" v-model="massChangeModal.data.update.reportValue">
            Valor laudo
            <i class="form-icon"></i>
          </label>
          <dx-input-number class="form-input"
                           v-model="massChangeModal.data.reportValue"
                           :disabled="!massChangeModal.data.update.reportValue"
                           :precision="2" maxlength="7"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column form-group">
          <label class="form-checkbox">
            <input type="checkbox"
                   v-model="massChangeModal.data.update.operationalCostValue">
            Custo operacional
            <i class="form-icon"></i>
          </label>
          <dx-input-number class="form-input" :precision="2"
                           v-model="massChangeModal.data.operationalCostValue"
                           :disabled="!massChangeModal.data.update.operationalCostValue"
          />
        </div>
<!--        <div class="column form-group">-->
<!--          <label class="form-checkbox">-->
<!--            <input type="checkbox"-->
<!--                   v-model="massChangeModal.data.update.chValue">-->
<!--            Valor de CH-->
<!--            <i class="form-icon"></i>-->
<!--          </label>-->
<!--          <dx-input-number class="form-input"-->
<!--                           v-model="massChangeModal.data.chValue"-->
<!--                           :precision="2"-->
<!--                           :disabled="!massChangeModal.data.update.chValue"-->
<!--          />-->
<!--        </div>-->
        <div class="column form-group">
          <label class="form-checkbox">
            <input type="checkbox" v-model="massChangeModal.data.update.filmValue">
            Valor m² do filme
            <i class="form-icon"></i>
          </label>
          <dx-input-number class="form-input"
                           v-model="massChangeModal.data.filmValue"
                           :disabled="!massChangeModal.data.update.filmValue"
                           :precision="2" maxlength="7"
          />
        </div>
        <div class="column form-group">
          <label class="form-checkbox">
            <input type="checkbox"
                   v-model="massChangeModal.data.update.filmQuantity">
            Qtde. filme
            <i class="form-icon"></i>
            <span class="c-hand tooltip tooltip-left"
                  data-tooltip="Taxa aplicada após a dedução de imposto">
                <fa-icon
                  class="text-info"
                  :icon="['fal', 'info-circle']"
                />
              </span>
          </label>
          <dx-input-number class="form-input"
                           v-model="massChangeModal.data.filmQuantity"
                           :precision="2" maxlength="7"
                           :disabled="!massChangeModal.data.update.filmQuantity"
          />
        </div>
        <div class="column form-group">
          <label class="form-checkbox">
            <input type="checkbox" v-model="massChangeModal.data.update.feeValue">
            Valor honorário
            <i class="form-icon"></i>
          </label>
          <dx-input-number class="form-input"
                           v-model="massChangeModal.data.feeValue"
                           :disabled="!massChangeModal.data.update.feeValue"
                           :precision="2" maxlength="7"
          />
        </div>
      </div>
      <div class="divider" />
      <label class="form-checkbox">
        <input type="checkbox" v-model="massChangeModal.data.update.checkValues">
        Atualizar parâmetros
        <i class="form-icon"></i>
      </label>
      <div class="columns">
        <div class="column col-5 col-md-12 col-sm-12 form-group">
          <label class="form-switch">
            <input type="checkbox"
                   v-model="massChangeModal.data.requireAuthorization"
                   :disabled="!massChangeModal.data.update.checkValues"
            />
            <i class="form-icon"/>Requer autorização do convênio
          </label>
          <label class="form-switch">
            <input type="checkbox"
                   v-model="massChangeModal.data.requireClinicalIndication"
                   :disabled="!massChangeModal.data.update.checkValues"
            />
            <i class="form-icon"/>Obrigatório indicação clínica (DUT)
          </label>
          <label class="form-switch">
            <input type="checkbox"
                   v-model="massChangeModal.data.requireWayTechnique"
                   :disabled="!massChangeModal.data.update.checkValues"
            />
            <i class="form-icon"/>Obrigatório campos via e técnica
          </label>
        </div>
        <div class="column col-4 col-md-12 col-sm-12 form-group">
          <label class="form-switch">
            <input type="checkbox"
                   v-model="massChangeModal.data.insuranceTax"
                   :disabled="!massChangeModal.data.update.checkValues"
            />
            <i class="form-icon"/>Aplicar imposto do plano
          </label>
          <label class="form-switch">
            <input type="checkbox"
                   v-model="massChangeModal.data.operationalCost"
                   :disabled="!massChangeModal.data.update.checkValues"
            />
            <i class="form-icon"/>Custo operacional
          </label>
          <label class="form-switch">
            <input type="checkbox"
                   v-model="massChangeModal.data.film"
                   :disabled="!massChangeModal.data.update.checkValues"
            />
            <i class="form-icon"/>Filme
          </label>
        </div>
        <div class="column col-3 col-md-12 col-sm-12 form-group">
          <label class="form-switch">
            <input type="checkbox"
                   v-model="massChangeModal.data.interest"
                   :disabled="!massChangeModal.data.update.checkValues"
            />
            <i class="form-icon"/>Juros
          </label>
          <label class="form-switch">
            <input type="checkbox"
                   v-model="massChangeModal.data.discount"
                   :disabled="!massChangeModal.data.update.checkValues"
            />
            <i class="form-icon"/>Desconto
          </label>
          <label class="form-switch">
            <input type="checkbox"
                   v-model="massChangeModal.data.rate"
                   :disabled="!massChangeModal.data.update.checkValues"
            />
            <i class="form-icon"/>Taxa de cartão
          </label>
        </div>
      </div>

      <template slot="footer">
        <button class="btn btn-primary btn-icon btn-icon-left mr-1"
                :class="{loading: massChangeModal.saving}"
                :disabled="massChangeModal.saving" @click="saveMassChange">
          <fa-icon :icon="['fal', 'save']" />Aplicar
        </button>
        <button class="btn" @click="massChangeModal.show = false">Sair</button>
      </template>
    </dx-modal>

    <dx-modal title="Copiar valores"
              id="modal-procedure-clone"
              v-if="cloneModal.show"
              v-model="cloneModal.show">
      <div class="loading loading-lg mt-2" v-if="cloneModal.loading" />
      <template v-else>
        <div class="item-alert">
          <fa-icon :icon="['fal', 'exclamation-triangle']" class="mr-2" />
          <span>
            Ao realizar este processo você irá copiar as regras do plano de origem para o destino.
            Este processo não poderá ser desfeito!
          </span>
        </div>
        <div class="form-group"
             :class="{'has-error': $v.cloneModal.insurancePlanId.$error}">
          <label class="form-label">Plano de origem</label>
          <select id="group" class="form-select"
                  v-model="cloneModal.insurancePlanId"
                  @blur="$v.cloneModal.insurancePlanId.$touch()">
            <option value="">Selecione o plano de origem</option>
            <option v-for="item in filteredInsurances" :key="item.id"
                    :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="text-center">
          <fa-icon class="text-error" :icon="['fal', 'arrow-down']" size="2x" />
        </div>
        <label class="form-label">Plano de destino</label>
        <input type="text" class="form-input text-bold"
               readonly
               :value="destinationInsuranceName">
      </template>
      <template slot="footer">
        <button class="btn btn-primary btn-icon btn-icon-left mr-1"
                :class="{loading: cloneModal.saving}"
                :disabled="cloneModal.saving"
                v-if="!cloneModal.loading"
                @click="saveClone">
          <fa-icon :icon="['fal', 'copy']" />Copiar
        </button>
        <button class="btn" @click="cloneModal.show = false">Sair</button>
      </template>
    </dx-modal>

    <dx-modal title="Importar procedimentos e valores"
              id="modal-import-procedures"
              v-if="importExportModal.show"
              v-model="importExportModal.show">
      <div class="item-alert">
        <fa-icon :icon="['fal', 'exclamation-triangle']" class="mr-2" />
        <span>Os procedimentos não encontrados através dos códigos não serão importados!</span>
      </div>
      <div class="import-body">
        <div>1 - Baixe um exemplo de como preencher a planilha</div>
        <div>2 - Para valores de repasse adicione as colunas "Valor repasse" "Tipo repasse"</div>
        <div>
          2.1 - Tipo repasse: precisa ser
          <strong class="text-info">value</strong> ou <strong class="text-info">percent</strong>
        </div>
        <div>
          3 - Para informar o código da tabela adicione a coluna "Tabela" (Valor padrão 22)
        </div>
        <div>4 - Selecione a planilha que será importada</div>
        <div class="divider" />
        <div class="body-action">
          <button class="btn btn-secondary btn-icon btn-icon-left mr-2"
                  :class="{loading: importExportModal.downloading}"
                  :disabled="importExportModal.downloading || importExportModal.uploading"
                  @click="downloadTemplate">
            <fa-icon :icon="['fal', 'arrow-down']" />1 - Baixar template
          </button>
          <button class="btn btn-secondary btn-icon btn-icon-left btn-upload"
                  :class="{loading: importExportModal.uploading}"
                  :disabled="importExportModal.downloading || importExportModal.uploading">
            <fa-icon :icon="['fal', 'upload']"/>2 - Selecionar e importar
            <input type="file" class="input-file" name="excel"
                   accept=".xlsx" @change="uploadTemplate">
          </button>
        </div>
      </div>
      <template slot="footer">
        <button class="btn" @click="importExportModal.show = false">Sair</button>
      </template>
    </dx-modal>

    <exceptions-modal
      v-if="exceptionModalShow"
      :show="exceptionModalShow"
      :origin="origin"
      :expense-insurances="items"
      @close="exceptionModalShow = false"
    />

    <manage-cbhpm-modal
      v-if="manageCbhpmModal.show"
      :show="manageCbhpmModal.show"
      :origin="origin"
      :items="items"
      @close="manageCbhpmModal.show = false"
      @saved="savedCbhpmModal"
    />
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import fuzzy from 'fuzzy';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import ExceptionsModal from './Exceptions.vue';
import PlanProcedureInsurance from './PlanProcedureInsurance.vue';
import ManageCbhpmModal from './ManageCBHPM.vue';

export default {
  mixins: [formMixin],
  components: {
    ExceptionsModal,
    ManageCbhpmModal,
    PlanProcedureInsurance,
  },
  props: {
    origin: {
      insurance: {
        id: {
          type: String,
        },
        name: {
          type: String,
        },
        type: {
          type: String,
        },
        plan: {
          id: {
            type: String,
          },
          name: {
            type: String,
          },
        },
        settings: {
          general: {
            type: Object,
          },
          tiss: {
            type: Object,
          },
        },
      },
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      professionals: [],
      filters: {
        search: '',
      },
      cloneModal: {
        loading: false,
        show: false,
        saving: false,
        insurancePlanId: '',
        insurances: [],
      },
      massChangeModal: {
        show: false,
        saving: false,
        tables: [
          {
            code: '00',
            name: 'Tabela própria',
          },
          {
            code: '19',
            name: 'Materiais',
          },
          {
            code: '20',
            name: 'Medicamentos',
          },
          {
            code: '22',
            name: 'Procedimentos',
          },
          {
            code: '98',
            name: 'Pacotes',
          },
        ],
        data: this.blankMassData(),
      },
      importExportModal: {
        downloading: false,
        uploading: false,
        show: false,
      },
      exceptionModalShow: false,
      manageCbhpmModal: {
        show: false,
      },
    };
  },
  async mounted() {
    this.loading = true;
    this.loadProfessionals();

    if (this.origin.type === 'plan') {
      await this.loadProcedures();
    } else {
      await this.loadInsurances();
    }

    await this.load();

    this.loading = false;
  },
  computed: {
    filteredItems() {
      let { items } = this;

      if (this.filters.search.trim()) {
        const results = fuzzy.filter(this.filters.search, items, {
          extract: (el) => {
            if (el.search) {
              return this.removeAccentuation(el.search);
            }
            return '';
          },
        });
        items = results.map(({ original }) => original);
      }

      return items;
    },
    filteredInsurances() {
      return this.cloneModal.insurances
        .filter(({ plan }) => plan.id !== this.origin.insurance.plan.id)
        .map(item => ({
          id: item.plan.id,
          name: item.customName,
          insuranceId: item.id,
        }));
    },
    destinationInsuranceName() {
      return this.cloneModal.insurances
        .find(item => item.plan.id === this.origin.insurance.plan.id).customName;
    },
    generalTable() {
      return this.origin.insurance.settings.general
        && this.origin.insurance.settings.general.table;
    },
  },
  validations() {
    return {
      cloneModal: {
        insurancePlanId: { required },
      },
    };
  },
  methods: {
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          items.forEach((item) => {
            item.professional.specialties.forEach((specialty) => {
              this.professionals.push({
                key: `${item.id}-${specialty.code}`,
                id: item.id,
                name: item.name,
                council: item.professional.councils[0],
                specialty,
              });
            });
          });
        })
        .catch(() => {});
    },
    loadProcedures() {
      // const types = [
      //   'consultation',
      //   'evaluation',
      //   'exam',
      //   'physiotherapy',
      //   'procedure',
      //   'service',
      //   'session',
      // ];
      const types = [
        'canteen',
        'consultation',
        'daily',
        'evaluation',
        'exam',
        'gas',
        'laboratory',
        'material',
        'medicine',
        'others',
        'physiotherapy',
        'procedure',
        'service',
        'session',
        'surgery',
        'tax',
      ];

      const params = {
        limit: 0,
        active: true,
        types: types.join(','),
      };

      return this.$httpX.get('/procedures', { params })
        .then(({ data }) => {
          this.items = data.items.map(item => ({
            id: '',
            code: item.tuss && item.tuss.code
              ? item.tuss.code
              : '',
            tableCode: this.getType(item.type),
            insurance: {
              id: this.origin.insurance.id,
              type: this.origin.insurance.type,
              name: this.origin.insurance.name,
              plan: {
                id: this.origin.insurance.plan.id,
                name: this.origin.insurance.plan.name,
              },
            },
            expense: {
              id: item.id,
              name: item.name,
              type: item.type,
              tuss: item.tuss || null,
              specialty: item.specialty,
              modality: item.modality,
            },
            return: '30',
            breakInsurance: false,
            professionals: 1,
            requireAuthorization: false,
            requireClinicalIndication: false,
            requireWayTechnique: false,
            hasProfessionalCustom: false,
            definition: this.blankDefinition(),
            selected: false,
            detail: false,
            search: `${item.tuss ? item.tuss.code : ''}${item.name}`,
          }));
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        });
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };

      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.items = data.items.map(item => ({
            id: '',
            code: this.origin.expense.code || '',
            tableCode: '00',
            insurance: {
              id: item.id,
              name: item.name,
              type: item.type,
              plan: {
                id: item.plan.id,
                name: item.plan.name,
              },
            },
            expense: {
              id: this.origin.expense.id,
              name: this.origin.expense.name,
              type: this.origin.expense.type,
              tuss: this.origin.expense.tuss,
              specialty: this.origin.expense.specialty,
              modality: this.origin.expense.modality,
            },
            return: '30',
            breakInsurance: false,
            professionals: 1,
            requireAuthorization: false,
            requireClinicalIndication: false,
            requireWayTechnique: false,
            hasProfessionalCustom: false,
            definition: this.blankDefinition(),
            selected: false,
            detail: false,
            search: item.name,
          }));
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        });
    },
    loadCloneInsurances() {
      const params = {
        limit: 0,
      };

      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.cloneModal.insurances = data.items;
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        });
    },
    load() {
      const params = {
        origin: this.origin.type,
        limit: 1000,
      };

      if (this.origin.type === 'plan') {
        params.insuranceId = this.origin.insurance.id;
        params.planId = this.origin.insurance.plan.id;
      } else {
        params.expenseId = this.origin.expense.id;
      }

      return this.$http
        .get('/expense-insurances', { params })
        .then(({ data }) => {
          data.items.forEach((selected) => {
            const found = this.items
              .find(item => item.expense.id === selected.expense.id
                && item.insurance.plan.id === selected.insurance.plan.id);

            if (found) {
              found.id = selected.id;
              found.insurance = selected.insurance;
              found.expense = selected.expense;
              found.code = selected.code;
              found.tableCode = selected.tableCode;
              found.return = selected.return;
              found.breakInsurance = selected.breakInsurance;
              found.requireAuthorization = selected.requireAuthorization;
              found.requireClinicalIndication = selected.requireClinicalIndication;
              found.requireWayTechnique = selected.requireWayTechnique;
              found.definition = selected.definition;
              found.hasProfessionalCustom = selected.hasProfessionalCustom;
              found.selected = true;
              if (found.definition.accreditation.enabled) {
                const { professional } = found.definition.accreditation;
                found.definition.accreditation
                  .professional.key = `${professional.id}-${professional.specialty.code}`;
              }
            }
          });
          this.items.sort((a, b) => {
            if (a.selected > b.selected) return -1;
            if (a.selected < b.selected) return 1;
            return 0;
          });
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        });
    },
    async reload() {
      this.loading = true;
      this.procedureItems = [];
      await this.loadProcedures();
      await this.load();
      this.loading = false;
    },
    openCbhpmModal() {
      this.manageCbhpmModal.show = true;
    },
    openMassChange() {
      this.massChangeModal.data = this.blankMassData();
      this.massChangeModal.show = true;
    },
    savedCbhpmModal() {
      this.manageCbhpmModal.show = false;
      this.reload();
    },
    saveMassChange() {
      const { update } = this.massChangeModal.data;
      const data = {};

      if (update.tableCode) {
        data.tableCode = this.massChangeModal.data.tableCode;
      }
      if (update.accredited) {
        data.accredited = this.massChangeModal.data.accredited;
      }
      if (update.insuranceValue) {
        data.insuranceValue = this.massChangeModal.data.insuranceValue;
      }
      if (update.particularValue) {
        data.particularValue = this.massChangeModal.data.particularValue;
      }
      if (update.transferValue) {
        data.transferType = this.massChangeModal.data.transferType;
        data.transferValue = this.massChangeModal.data.transferValue;
      }
      if (update.taxValue) {
        data.taxValue = this.massChangeModal.data.taxValue;
      }
      if (update.reportValue) {
        data.reportValue = this.massChangeModal.data.reportValue;
      }
      if (update.operationalCostValue) {
        data.operationalCostValue = this.massChangeModal.data.operationalCostValue;
      }
      if (update.chValue) {
        data.chValue = this.massChangeModal.data.chValue;
      }
      if (update.filmValue) {
        data.filmValue = this.massChangeModal.data.filmValue;
      }
      if (update.filmQuantity) {
        data.filmQuantity = this.massChangeModal.data.filmQuantity;
      }
      if (update.feeValue) {
        data.feeValue = this.massChangeModal.data.feeValue;
      }
      if (update.checkValues) {
        data.requireAuthorization = this.massChangeModal.data.requireAuthorization;
        data.requireClinicalIndication = this.massChangeModal.data.requireClinicalIndication;
        data.requireWayTechnique = this.massChangeModal.data.requireWayTechnique;
        data.insuranceTax = this.massChangeModal.data.insuranceTax;
        data.operationalCost = this.massChangeModal.data.operationalCost;
        data.film = this.massChangeModal.data.film;
        data.interest = this.massChangeModal.data.interest;
        data.discount = this.massChangeModal.data.discount;
        data.rate = this.massChangeModal.data.rate;
      }

      if (Object.keys(data).length === 0) {
        this.$toast.error('Nenhuma informação habilitada para salvar!');
        return null;
      }

      return this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente atualizar os campos selecionados?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              close();
              this.massChangeModal.saving = true;
              const insuranceId = this.origin.insurance.id;
              const planId = this.origin.insurance.plan.id;

              return this.$http
                .post(`/insurances/${insuranceId}/plans/${planId}/procedure-bulk`, data)
                .then(() => {
                  this.massChangeModal.show = false;
                  this.reload();
                })
                .catch(() => {
                  this.$toast.error('Ocorreu um erro. Tente novamente!');
                })
                .finally(() => {
                  this.massChangeModal.saving = false;
                });
            },
          },
        ],
      });
    },
    async openClone() {
      this.cloneModal.loading = true;
      if (this.cloneModal.insurances.length === 0) {
        await this.loadCloneInsurances();
      }
      this.$v.cloneModal.insurancePlanId.$reset();
      this.cloneModal.insurancePlanId = '';
      this.cloneModal.loading = false;
      this.cloneModal.show = true;
    },
    saveClone() {
      if (this.cloneModal.saving) {
        return;
      }

      this.$v.cloneModal.insurancePlanId.$touch();
      if (this.$v.cloneModal.insurancePlanId.$error) {
        return;
      }

      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente realizar este processo?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              close();
              this.cloneModal.saving = true;
              const insuranceId = this.origin.insurance.id;
              const planId = this.origin.insurance.plan.id;
              const data = {
                originPlanId: this.cloneModal.insurancePlanId,
              };
              this.$http
                .post(`/insurances/${insuranceId}/plans/${planId}/procedure-clone`, data)
                .then(() => {
                  this.reload();
                  this.cloneModal.show = false;
                })
                .catch(() => {
                  this.$toast.error('Ocorreu um erro. Tente novamente!');
                })
                .finally(() => {
                  this.cloneModal.saving = false;
                });
            },
          },
        ],
      });
    },
    async setAction({ value }) {
      if (this.importExportModal.saving) {
        return;
      }
      try {
        this.importExportModal.saving = true;
        const insuranceId = this.origin.insurance.id;
        const planId = this.origin.insurance.plan.id;
        if (value === 'export') {
          await this.$file
            .download(`/insurances/${insuranceId}/plans/${planId}/export-procedures`);
        } else {
          this.importExportModal.show = true;
        }
      } catch (e) {
        this.$toast.error('Ocorreu um erro. Tente novamente!');
      } finally {
        this.importExportModal.saving = false;
      }
    },
    downloadTemplate() {
      const insuranceId = this.origin.insurance.id;
      const planId = this.origin.insurance.plan.id;
      const params = { template: true };
      this.$file
        .download(`/insurances/${insuranceId}/plans/${planId}/export-procedures`, params);
    },
    async uploadTemplate(e) {
      e.preventDefault();
      if (e.target.files.length) {
        this.importExportModal.uploading = true;
        const [file] = e.target.files;

        const formData = new FormData();
        formData.append('file', file);

        try {
          const insuranceId = this.origin.insurance.id;
          const planId = this.origin.insurance.plan.id;
          await this.$http
            .post(`/insurances/${insuranceId}/plans/${planId}/import-procedures`, formData);
          await this.reload();
          this.$toast.show('Tabela importada com sucesso!');
          this.importExportModal.show = false;
        } catch (error) {
          this.$toast.error('Erro ao importar o arquivo!');
        } finally {
          this.importExportModal.uploading = false;
        }
      }
    },
    getType(type) {
      if (type === 'daily') {
        return '18';
      }
      if (type === 'material') {
        return '19';
      }
      if (type === 'medicine') {
        return '20';
      }
      if (type === 'others') {
        return '00';
      }
      if (type === 'procedure') {
        return '22';
      }
      return '00';
    },
    blankDefinition() {
      return {
        accreditation: {
          enabled: false,
          professional: {
            id: '',
            name: '',
            council: {
              name: '',
              state: '',
              record: '',
            },
            specialty: {
              code: '',
              name: '',
            },
          },
        },
        accredited: 'pj',
        chValue: '',
        chQuantity: '',
        operationalCostValue: '',
        filmValue: '',
        filmQuantity: '',
        feeValue: '',
        insuranceValue: '',
        particularValue: '',
        reportValue: '',
        taxValue: '',
        taxType: 'pj',
        transferValue: '',
        transferType: 'value',
        postageCode: '',
        tax: true,
        rate: true,
        discount: true,
        interest: true,
        operationalCost: true,
        film: true,
        insuranceTax: true,
      };
    },
    blankInsurance() {
      return {
        id: '',
        name: '',
        type: '',
        plan: {
          id: '',
          name: '',
        },
      };
    },
    blankExpense() {
      return {
        id: '',
        name: '',
        type: '',
        tuss: '',
        specialty: '',
        modality: '',
      };
    },
    blankMassData() {
      return {
        update: {
          tableCode: false,
          accredited: false,
          insuranceValue: false,
          particularValue: false,
          transferValue: false,
          taxValue: false,
          reportValue: false,
          operationalCostValue: false,
          chValue: false,
          filmValue: false,
          filmQuantity: false,
          feeValue: false,
          checkValues: false,
        },
        tableCode: '22',
        accredited: 'pj',
        chValue: '',
        chQuantity: '',
        operationalCostValue: '',
        filmValue: '',
        filmQuantity: '',
        feeValue: '',
        insuranceValue: '',
        particularValue: '',
        reportValue: '',
        taxValue: '',
        taxType: 'pj',
        transferValue: '',
        transferType: 'value',
        postageCode: '',
        tax: true,
        rate: true,
        discount: true,
        interest: true,
        operationalCost: true,
        film: true,
        insuranceTax: true,
        requireAuthorization: false,
        requireClinicalIndication: false,
        requireWayTechnique: false,
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
@import 'src/assets/scss/colors';

.insurance-procedures {
  .has-error-message {
    background-color: lighten($error-color, 25%);
    border: $error-color solid $border-width;
    border-radius: $border-radius;
    font-size: $font-size-xs;
    margin-top: $layout-spacing;
    padding: $layout-spacing-sm $layout-spacing;
  }

  .procedure-title {
    background-color: $blue-color-100;
    font-weight: bold;
    margin-top: $layout-spacing;
    padding: $layout-spacing $layout-spacing;
  }

  .btn-setting {
    background-color: #6C5FFF;
    a {
      color: $light-color;
      line-height: 0;
    }
    li {
      a {
        color: $dark-color;
        line-height: 1.1rem;
      }
    }
  }
}

#modal-mass-change {
  .item-alert {
    background-color: $red-color-100;
    border: $border-width solid $red-color-300;
    border-radius: $border-radius;
    font-weight: 500;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
    svg {
      color: $red-color-600;
    }
  }
}

#modal-procedure-clone {
  .item-alert {
    background-color: $red-color-100;
    border: $border-width solid $red-color-300;
    border-radius: $border-radius;
    font-weight: 500;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
    svg {
      color: $red-color-600;
    }
  }
}

#modal-import-procedures {
  .item-alert {
    background-color: $red-color-100;
    border: $border-width solid $red-color-300;
    border-radius: $border-radius;
    font-weight: 500;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
    svg {
      color: $red-color-600;
    }
  }
  .import-body {
    padding: $layout-spacing-lg 0;
    .body-action {
      text-align: center;
      .btn {
        height: 35px;
        width: 200px;
      }
      .btn-upload {
        position: relative;
        .input-file {
          display: block;
          height: 100%;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }
  }
}
</style>
