<template>
  <dx-modal title="Gerenciar agendas"
            id="modal-schedule-config"
            size="lg"
            v-if="show"
            :value="show"
            @input="close">
    <p v-if="type === 'insurance'">Informe as agendas que atenderão o plano
      <strong class="text-primary">{{ name }}</strong>
    </p>
    <p v-else>Informe as agendas que atenderão o serviço
      <strong class="text-primary">{{ name }}</strong>
    </p>
    <div class="loading loading-lg" v-if="loading" />
    <template v-else>
      <div class="empty" v-if="schedules.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
        </div>
        <p class="empty-title h6">Agendas</p>
        <p class="empty-subtitle">Nenhuma agenda configurada!</p>
      </div>
      <table class="table table-striped" v-else>
        <thead>
        <tr>
          <th style="width: 50px">
            <label class="form-checkbox">
              <input type="checkbox"
                     @change="selectAllSchedules"
                     :checked="getScheduleSelected"
                     :indeterminate.prop="getScheduleSelected === null">
              <i class="form-icon"></i>
            </label>
          </th>
          <th>Nome da agenda</th>
          <th>Tipo</th>
          <th class="text-center">Data</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in schedules" :key="i">
          <td>
            <label class="form-checkbox">
              <input type="checkbox" v-model="item.selected">
              <i class="form-icon"></i>
            </label>
          </td>
          <td>{{ item.scheduleName }}</td>
          <td>{{ item.type === 'week' ? 'Semanal' : 'Escala' }}</td>
          <td class="text-center">{{ getDay(item.type, item.day) }}</td>
        </tr>
        </tbody>
      </table>
    </template>
    <template slot="footer">
      <button class="btn btn-primary btn-icon btn-icon-left mr-1"
              :class="{loading: saving}"
              :disabled="saving"
              v-if="schedules.length > 0"
              @click="save">
        <fa-icon :icon="['fal', 'save']" /> Salvar
      </button>
      <button class="btn" @click="close">Sair</button>
    </template>
  </dx-modal>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    show: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    insuranceId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      schedules: [],
    };
  },
  computed: {
    getScheduleSelected() {
      if (!this.schedules || this.schedules.length === 0) {
        return false;
      }

      const selected = this.schedules.filter(item => item.selected);
      if (selected.length === 0) {
        return false;
      }
      if (selected.length === this.schedules.length) {
        return true;
      }
      return null;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http
        .get('/schedule-config/get-settings')
        .then(({ data }) => {
          this.schedules = data.items.map((item) => {
            let found;
            if (this.type === 'insurance') {
              found = item.insurancePlans.some(id => id === this.id);
            } else {
              found = item.expenses.some(id => id === this.id);
            }
            return {
              id: item.id,
              scheduleName: item.schedule.name,
              type: item.type,
              day: item.day,
              selected: found,
            };
          });
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    selectAllSchedules(e) {
      this.schedules.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
    async save() {
      this.saving = true;

      const data = {
        type: this.type,
        id: this.id,
        schedules: this.schedules.map(item => ({
          id: item.id,
          selected: item.selected,
        })),
      };

      if (this.type === 'insurance') {
        data.insuranceId = this.insuranceId;
      }

      return this.$http
        .post('/schedule-config/bulk-settings', data)
        .then(() => {
          this.$toast.show('Agendas atualizadas!');
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    close() {
      this.$emit('close');
    },
    getDay(type, day) {
      if (type === 'week') {
        return moment(day, 'day').format('dddd');
      }
      return moment(day).format('DD/MM/YYYY');
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';

.modal-schedule-config {

}
</style>
